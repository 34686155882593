<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-address-book"></i> Client Account</b-card-title>
			<b-card-sub-title>Manages the client account registration</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>

					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date From"
											description="Refer to ACTIVE accounts within this date range">
											<b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en"
												reset-button label-reset-button="Clear"
												:date-format-options="dateFormatOptions" />
											<span v-show="errors.has('Date From')" class="help-block">
												{{ errors.first('Date From') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date To"
											description="Refer to ACTIVE accounts within this date range">
											<b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en"
												reset-button label-reset-button="Clear"
												:date-format-options="dateFormatOptions" />
											<span v-show="errors.has('Date To')" class="help-block">
												{{ errors.first('Date To') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Asset Owner">
											<v-select name="Asset Owner" class="style-chooser" label="text" placeholder=" - Please select - "
												:options="allAssetOwnerOptions" :reduce="(company) => company.value"
												v-model="filterBy.assetOwner">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Client">
											<v-select name="Client" class="style-chooser" label="text" placeholder=" - Please select - "
												:options="allClientOptions" :reduce="(company) => company.value"
												v-model="filterBy.client">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Asset Type">
											<v-select name="Asset Type" class="style-chooser" label="text" placeholder=" - Please select - "
												:options="allAssetTypesOptions" :reduce="(assetType) => assetType.value"
												v-model="filterBy.assetType">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Status">
											<b-form-select name="Status" v-model="filterBy.status"
												:options="statusOptions" class="mr-2" />
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Account No">
											<v-select id="accountNo" name="Account No" class="style-chooser"
												label="text" placeholder=" - Please select - " :options="allClientAccountOptions"
												:reduce="(account) => account.value" v-model="filterBy.clientAccount">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown id="client-account-select-actions" text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-client-account v-show="!isViewer">
								Add Client Account
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls"
									:name="fileName + '.xls'">
									Export Client Accounts in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFieldsCSV" type="csv"
									:name="fileName + '.csv'">
									Export Client Accounts to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table ref="clientAccountsTable" show-empty striped hover :items="items" :fields="fields"
					:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

					<template v-slot:cell(accountNo)="row">
						<span class="numFont">
							{{ row.item.accountNo }}
						</span>
					</template>

					<template v-slot:cell(contractPeriod)="row">
						<span class="text-nowrap">
							Started <strong> {{ getFormattedDate(row.item.startDate) }} </strong> with <strong>{{
								row.item.contractPeriod }}</strong> contract period
						</span>
					</template>

					<template v-slot:cell(actions)="row">
						<ClientAccountRowActions :row="row" :isSuperAdmin="isSuperAdmin" :isManager="isManager"
							:isViewer="isViewer" />
					</template>

					<template v-slot:cell(status)="row">
						<ClientAccountRowStatus :row="row" />
					</template>

					<template slot="row-details" slot-scope="row">
						<ClientAccountDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- Modals here -->
		<AddClientAccount :allAssetOwnerOptions="allAssetOwnerOptions" :allClientOptions="allClientOptions"
			:allConnectionsObj="allConnectionsObj" :allUsersObj="allUsersObj" :allAssetTypesObj="allAssetTypesObj"
			:allClientAccountsObj="allClientAccountsObj" />
		<EditClientAccount :allAssetOwnerOptions="allAssetOwnerOptions" :allClientOptions="allClientOptions"
			:allConnectionsObj="allConnectionsObj" :allUsersObj="allUsersObj" :allAssetTypesObj="allAssetTypesObj"
			:allClientAccountsObj="allClientAccountsObj" />
		<ConfirmAccountUpdate />
		<ConfirmAccountChangeRequest />
		<ConfirmAccountStatusUpdate />
		<PrintAccountSummary :allCompaniesObj="allCompaniesObj" />
	</div>
</template>

<script>
// Components
import AddClientAccount from './clientAccount/AddClientAccount';
import ClientAccountDetailsView from './clientAccount/ClientAccountDetailsView';
import ClientAccountRowActions from './clientAccount/ClientAccountRowActions';
import ClientAccountRowStatus from './clientAccount/ClientAccountRowStatus';
import ConfirmAccountChangeRequest from './clientAccount/ConfirmAccountChangeRequest.vue';
import ConfirmAccountUpdate from './clientAccount/ConfirmAccountUpdate';
import ConfirmAccountStatusUpdate from './clientAccount/ConfirmAccountStatusUpdate';
import EditClientAccount from './clientAccount/EditClientAccount';
import PrintAccountSummary from './clientAccount/PrintAccountSummary';

// Utils
import { ClientAccountUtil } from '@/utils/clientAccountUtil';
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { UserUtil } from '@/utils/userutil';

// API & DAO
import clientAccountApi from '@/api/clientAccountApi';
import clientAccountDAO from '@/database/clientAccounts';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import JsonExcel from 'vue-json-excel';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'client-account',
	components: {
		AddClientAccount,
		EditClientAccount,
		ClientAccountDetailsView,
		ClientAccountRowStatus,
		ClientAccountRowActions,
		ConfirmAccountUpdate,
		ConfirmAccountStatusUpdate,
		ConfirmAccountChangeRequest,
		PrintAccountSummary,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'accountNo',
					sortable: true,
				},
				{
					key: 'contractPeriod',
					sortable: true,
				},
				{
					key: 'assetOwner',
					sortable: true,
				},
				{
					key: 'client',
					sortable: true,
				},
				{
					key: 'status',
					class: 'text-center'
				},
				{
					key: 'actions',
					class: 'text-center'
				}
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			dateFormatOptions: { ...config.dateFormatOptions },

			allAssetOwnerOptions: [],
			allClientOptions: [],
			allAssetTypesOptions: [],
			allClientAccountOptions: [],
			statusOptions: config.accountStatus,

			allCompaniesObj: {},
			allConnectedCompaniesObj: {},
			allConnectionsObj: {},
			allAssetTypesObj: {},
			allUsersObj: {},

			allClientAccountsObj: {},
			selClientAccount: {},

			defaultFilterBy: {
				dateFrom: "",
				dateTo: "",
				assetOwner: { ...config.companyDefaultValue },
				client: { ...config.companyDefaultValue },
				assetType: { ...config.assetTypeDefaultValue },
				clientAccount: { ...config.clientAccountDefaultValue },
				status: 'Active',
			},
			filterBy: {
				dateFrom: "",
				dateTo: "",
				assetOwner: { ...config.companyDefaultValue },
				client: { ...config.companyDefaultValue },
				assetType: { ...config.assetTypeDefaultValue },
				clientAccount: { ...config.clientAccountDefaultValue },
				status: 'Active',
			},
			prevFilterBy: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			isManager: this.$store.getters.isManager,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,

			// Listener
			accountListener: null,
		};
	},
	computed: {
		dateFrom() {
			const dateTo = moment();
			const dateFrom = dateTo.add(-30, 'days');
			return dateFrom.format('YYYY-MM-DD');
		},
		dateTo() {
			return moment().format('YYYY-MM-DD');
		},

		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				'Account No': 'accountNo',
				'Contract Period': 'contractPeriod',
				'Asset Owner': 'assetOwner',
				'Client': 'client',
				Status : 'status',
				'Start Date': 'Start Date',
				'Billing Frequency': 'billingFrequency',
				'Total Billings': 'totalBillings',
				'Asset Types': 'Asset Types',
				'Principal': 'principal',
				'Client Manager': 'clientManager',
				'Date Created': 'Date Created',
				'Created By': 'Created By',
				'Date Updated': 'Date Updated',
				'Updated By': 'Updated By',
				'Reason for Update': 'Reason for Update'
			};
		},

		exportFieldsCSV() {
			return {
				'Account No': 'accountNo',
				'Contract Period': 'contractPeriod',
				'Asset Owner': 'assetOwner',
				'Client': 'client',
				'Start Date': 'Start Date',
				'Billing Frequency': 'billingFrequency',
				'Total Billings': 'totalBillings',
				'Asset Types': 'Asset Types',
				'Principal': 'principal',
				'Client Manager': 'clientManager',
				'Date Created': 'Date Created',
				'Created By': 'Created By',
				'Date Updated': 'Date Updated',
				'Updated By': 'Updated By',
				'Reason for Update': 'Reason for Update'
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Client-Accounts-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				// reset filter to default
				this.filterBy = { ...this.defaultFilterBy };

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allConnectedCompaniesObj = { ...this.$store.getters.connectedCompanies };
				this.allConnectionsObj = { ...this.$store.getters.connections };
				this.allUsersObj = { ...this.$store.getters.users, ...this.$store.getters.connectedUsers };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
				this.allClientAccountsObj = { ...this.$store.getters.clientAccounts };


				this.allAssetOwnerOptions = this.getAssetOwnerOptions();

				if (this.isSuperAdmin) {
					this.allClientOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				} else {
					this.allClientOptions = DropDownItemsUtil.retrieveActiveCompanies(this.allConnectedCompaniesObj);
					this.allCompaniesObj = { ...this.allCompaniesObj, ...this.allConnectedCompaniesObj };
				}

				this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);
				this.allClientAccountOptions = DropDownItemsUtil.retrieveClientAccounts(this.allClientAccountsObj);

				await this.retrieveData();
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}

		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseSaveClientAccount', (accountObj) => {
			this.updateTable(accountObj);
		});
	},
	methods: {
		getAssetOwnerOptions() {
			let filteredCompaniesObj = {};

			// filter by subcription to billing
			filteredCompaniesObj = _.filter(this.allCompaniesObj, o => {
				return o.permissions && o.permissions.billing;
			});

			if (!this.isSuperAdmin) {
				// filter by company access if company view
				let companyId = this.loggedUserCompany.id;
				filteredCompaniesObj = _.filter(filteredCompaniesObj, o => {
					return o.id === companyId || o.parentCompanyId === companyId;
				});
			}

			return DropDownItemsUtil.retrieveAssetOwners(filteredCompaniesObj);
		},
		listenerCallback(type, accountObj) {
			if ((!this.allClientAccountsObj[accountObj.id] && type === "added") || type === "modified") {
				this.allClientAccountsObj[accountObj.id] = accountObj;
				this.$store.dispatch('updateAllClientAccounts', this.allClientAccountsObj);
				this.filterClientAccounts();
			}
		},
		updateTable(accountObj) {
			if (!_.isEmpty(accountObj)) {
				this.allClientAccountsObj[accountObj.id] = accountObj;
				this.$store.dispatch('updateAllClientAccounts', this.allClientAccountsObj);
				this.filterClientAccounts();
			}
		},

		validateFilter() {
			let isValid = true;

			if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
				(!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
				this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
				isValid = false;
			} else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
				this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
				isValid = false;
			}

			return isValid;
		},
		async onFilterRequest() {
			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilterBy)) {
				await this.retrieveData();
				this.prevFilterBy = { ...this.filterBy };
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilterBy = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();
			}
		},
		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.companyId = this.loggedUserCompany.id;
				filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
				filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

				if (this.isSuperAdmin) {
					const { data } = await clientAccountApi.getClientAccounts(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allClientAccountsObj = data.clientAccounts;
				} else {
					const { data } = await clientAccountApi.getClientAccounts(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);

					this.allClientAccountsObj = data.clientAccounts;
				}

				this.filterClientAccounts();

				// Update listener
				this.accountListener = clientAccountDAO.getAccountListener(filter, this.listenerCallback);

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		filterClientAccounts() {
			let filteredObjs = { ...this.allClientAccountsObj };

			_.forEach(this.allClientAccountsObj, (account, id) => {
				this.filterByCompany(filteredObjs, account, id);

				// Filter By Start Date
				if (this.filterBy.dateFrom && this.filterBy.dateFrom.length > 0) {
					let fromTimestamp = DateUtil.startDateTimeStamp(new Date(this.filterBy.dateFrom));
					if (account.startDate < fromTimestamp) {
						delete filteredObjs[id];
					}
				}
				// Filter By End Date
				if (this.filterBy.dateTo && this.filterBy.dateTo.length > 0) {
					let toTimestamp = DateUtil.endDateTimeStamp(new Date(this.filterBy.dateTo));
					if (account.endingDate > toTimestamp) {
						delete filteredObjs[id];
					}
				}

				let client = this.filterBy.client;
				if (client && client.id && client.id !== account.clientId) {
					delete filteredObjs[id];
				}

				let assetType = this.filterBy.assetType;
				if (assetType && assetType.id && !ClientAccountUtil.hasAssetType(account, assetType.id)) {
					delete filteredObjs[id];
				}

				let status = this.filterBy.status;
				if (status && status.length > 0 && status !== account.status) {
					delete filteredObjs[id];
				}

				let clientAccount = this.filterBy.clientAccount;
				let accountNo = clientAccount.accountNo ? clientAccount.accountNo : '';
				if (accountNo && accountNo.length > 0 && accountNo !== account.accountNo) {
					delete filteredObjs[id];
				}
			});

			this.processClientAccounts(filteredObjs)
		},
		filterByCompany(filteredObjs, clientAccount, id) {
			let companyIdFilter = this.filterBy.assetOwner.id;
			let assetOwnerId = clientAccount.assetOwnerId;
			if (companyIdFilter && companyIdFilter !== assetOwnerId) {
				delete filteredObjs[id];
			}

			if (!this.isSuperAdmin) {
				let companyObj = this.allCompaniesObj[assetOwnerId];
				if (companyObj.id !== assetOwnerId && companyObj.parentCompanyId !== assetOwnerId) {
					delete filteredObjs[id];
				}
			}
		},
		processClientAccounts(clientAccounts) {
			this.items = Object.values(clientAccounts);
			this.items = _.sortBy(this.items, ['dateCreated']);
			this.items.reverse();

			this.items.forEach((item) => {
				item['Asset Types'] = ClientAccountUtil.getAssetTypeDisplay(item);
				item['Reason for Update'] = item.updateReason;

				// Users Display
				item['Created By'] = UserUtil.getUserDisplay(this.allUsersObj, item.createdBy);
				item['Updated By'] = UserUtil.getUserDisplay(this.allUsersObj, item.createdBy);

				// Parse timestamps
				item['Start Date'] = this.getFormattedDateWithTime(item.startDate);
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
			});
			this.totalRows = this.items.length;
			this.$store.dispatch('updateAllClientAccounts', clientAccounts);

			// remove show details
			_.forEach(this.items, item => {
				delete item._showDetails;
			});

			// refresh table
			if (this.$refs.clientAccountsTable) {
				this.$refs.clientAccountsTable.refresh();
			}
		},

		// UTILS
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getFormattedDate(date) {
			return DateUtil.getFormattedDate(date);
		}
	},
	beforeUnmount() {
		if (this.accountListener != null) {
			// Stop listening to changes
			this.accountListener();
		}
	},
	beforeDestroy() {
		EventBus.$off('onCloseSaveClientAccount');
	},
}
</script>